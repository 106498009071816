import React, {useState, useEffect} from 'react';
import '../styles/scoring/strategyPlanning.scss'
import Exclamation from '../icons/AiFillExclamationCircle.svg';
import { Select, Tooltip, Input } from 'antd';
const StrategyPlanning = ({ title, criteria, handleNext, handleBack, handleFormDataChange, currentScore, validationErrors }) => {
  const criterias = criteria.criterias
  const attributes = criterias[0].attributes
  const { TextArea } = Input;
  const [selectedValues, setSelectedValues] = useState({});
  const [reason, setReason] = useState("");
  const [error, setError] = useState('');
  const [totalScore, setTotalScore] = useState(0);
  const score = [{
    label: '1',
    value: 1
  }, {
    label: '2',
    value: 2
  }, {
    label: '3',
    value: 3
  }, {
    label: '4',
    value: 4
  }, {
    label: '5',
    value: 5
  }, {
    label: '6',
    value: 6
  }, {
    label: '7',
    value: 7
  }, {
    label: '8',
    value: 8
  }, {
    label: '9',
    value: 9
  }, {
    label: '10',
    value: 10
  }]
  useEffect(() => {
    if (currentScore && currentScore.data && currentScore.data.scores.length > 0) {
      const initialSelectedValues = {};
      let initialReason = "";
      // Find the score object for the current category (assuming category 3 for presentation)
      const strategyPlanningScore = currentScore.data.scores.find(score => score.id === 1);
      if (strategyPlanningScore) {
        strategyPlanningScore.attributes.forEach(attribute => {
          initialSelectedValues[attribute.id] = attribute.score;
        });
        initialReason = strategyPlanningScore.reason;
      }
  
      setSelectedValues(initialSelectedValues);
      setReason(initialReason);
    }
  }, [currentScore]);
  const handleScoreChange = (value, attributeId) => {
    const numericAttributeId = Number(attributeId);
    setSelectedValues(prevValues => ({
      ...prevValues,
      [numericAttributeId]: value
    }));
    handleFormDataChange(1, numericAttributeId, value, reason);
  };
  const handleReasonChangeLocal = (e) => {
    const reason = e.target.value;
    const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~ ]*$/;
    setReason(reason);
    if (regex.test(reason)) {
      setError('');
    } else {
      setError('Your input contains invalid characters. Use English letters only.');
    }
    Object.keys(selectedValues).forEach(attributeId => {
      const numericAttributeId = Number(attributeId);
      handleFormDataChange(1, numericAttributeId, selectedValues[numericAttributeId], reason);
    });
  };
  useEffect(() => {
    const calculateTotalScore = () => {
      let total = 0;
      let weightSum = 0;

      attributes.forEach(attribute => {
        const value = selectedValues[attribute.id] || 0;
        const weight = attribute.weight || 1;
        total += value * weight;
        weightSum += weight;
      });

      setTotalScore(weightSum ? (total / weightSum).toFixed(2) : 0);
    };

    calculateTotalScore();
  }, [selectedValues, attributes]);
  return (
    <>
      <div className="strategy">
        <div className="strategy-content">
          <div className="content-head">
            <h1 className="H2">{title}</h1>
          </div>
          <div className="content-head2">
            <span className="Body">{criterias[0].name}</span>
            <div className="hint">
              <span className="Body">Hover Icon Scoring Value Per Attribute</span>
              <Tooltip placement="topRight" title={
                <div>
                  <span className="Body">Scoring Value per Attribute</span><br /><br />
                  <ul>
                    <li className="Body">1-2 = Made no attempt to the attributes</li>
                    <li className="Body">3-4 = Attempt to meet 25% of the attributes</li>
                    <li className="Body">5-6 = Achieved 50% of the attributes</li>
                    <li className="Body">7-8 = Achieved more than 75% of the attributes</li>
                    <li className="Body">9-10 = Exceeds all attributes</li>
                  </ul><br /><br />
                  <span className="Body">0 is not allowed to be used by the judges to score any attribute.</span>
                </div>
              }>
                <img src={Exclamation} width={16} height={16} alt="tooltips" />
              </Tooltip>
            </div>
          </div>
          <div className="content-scoring">
            {attributes.map(attribute => (
              <div key={attribute.id} className="attribute-item">
                <div className="attribute-left">
                  <span className="Subtitle attribute-name">
                    {attribute.name}
                  </span>
                  <span className="Body attribute-weight">({(attribute.weight * 100).toFixed(0)}%)</span>
                </div>
                <Select placeholder="Select score"
                  options={score}
                  value={selectedValues[attribute.id]}
                  onChange={(value) => handleScoreChange(value, attribute.id)}
                  className={selectedValues[attribute.id] ? '' : (validationErrors[1] ? 'error-scoring .ant-select-selector' : '')}
                  />
              </div>
            ))}
            <div className="scoring-reason">
              <div className="topic-left">
                <span className="Subtitle topic">Scoring Reason</span>
                <span className="topic-h">(Optional)</span>
              </div>
              <div className={error? 'input-textarea input-textarea-error' : 'input-textarea'}>
              <TextArea id={error? 'errorInput' : ''} value={reason} showCount onChange={handleReasonChangeLocal} maxLength={500} placeholder='Provide the reasons for scoring each item.' />
              {error && <span className="Body" style={{ color: 'red' }}>{error}</span>}
              </div>
            </div>
          </div>
          <div className="content-footer">
            <div className="footer-total-score">
              <span className="H2">Total Score: {totalScore}/10</span>
            </div>
            <div className="footer-btn">
              <button className="Body btn-back" onClick={handleBack}>Back</button>
              <button className="Body btn-next" onClick={handleNext}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default StrategyPlanning;