import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import '../../styles/submitFormButton.scss'
import { useNavigate } from 'react-router-dom';
const SubmitFormBtn = ({ onSubmit, isEdit, isEditSuccess, onSecondModalClose, disabled }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    onSubmit();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSecondModalClose = () => {
    setIsSecondModalOpen(false);
    onSecondModalClose();
    navigate('/overview')
  };
  useEffect(() => {
    if (isEditSuccess) {
      setIsSecondModalOpen(true);
    }
  }, [isEditSuccess]);
  return (
    <>
      <Button disabled={disabled} className={disabled ? "" : "btn-submit"} type="primary" onClick={showModal}>
      {isEdit === true ? 'Save Changes' : 'Submit'}
      </Button>
      <Modal className="submit-modal" closeIcon={false} footer={null} open={isModalOpen}>
        <div className="submit-modal-content">
            <h1 className="H1">{isEdit ? 'Confirm Your Changes' : 'Confirm Your Submission'}</h1>
            <p className="Body">{isEdit ? 
            'Are you sure you want to save these changes?' : 
            'Are you sure you want to submit your project for the ASEAN Digital Awards 2025?'}
            </p>
            <div className="button-section">
                <button className="btn-cancel" onClick={handleCancel}>Cancel</button>
                <button className="btn-submit" onClick={handleOk}>{isEdit ? 'Confirm' : 'Submit'}</button>
            </div>
        </div>
      </Modal>
      <Modal className="second-modal" closeIcon={false} footer={null} open={isSecondModalOpen}>
        <div className="second-modal-content">
          <h1 className="H1">Changes Saved Successfully</h1>
          <p className="Body">Your project submission has been updated successfully. All changes have been saved. </p>
          <div className="button-section">
            <button className="btn-submit" onClick={handleSecondModalClose}>Close</button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SubmitFormBtn;