import React, { useState, useEffect, useRef } from 'react';
import { Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';

const ImageUploader = ({ onFileTooLarge, onInvalidFileType, onFileUpload, editPreviewURL }) => {
  const [fileList, setFileList] = useState([]);
  const initialLoad = useRef(true)
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList.slice(-1));
  };
  useEffect(() => {
    if (initialLoad.current && editPreviewURL && typeof editPreviewURL.url === 'string') {
      setFileList([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: editPreviewURL.url,
        },
      ]);
      initialLoad.current = false;
    }
  }, [editPreviewURL]);
  const onPreview = async (file) => {
    let src = file.url;
    
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Invalid file format. Please upload a picture in [ JPG, PNG ]');
      onInvalidFileType(true);
      return false;
    }
    onInvalidFileType(false);
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
      onFileTooLarge(true); // Set the file size warning
      console.log('File is too large:', file.size);
    } else {
      onFileTooLarge(false); // Reset the file size warning
      onFileUpload(file);
      // console.log('File size is acceptable:', file.size);
    }
    return isJpgOrPng && isLt2M;
  };

  const onRemove = () => {
    onFileTooLarge(false); // Reset the file size warning when a file is removed
    onInvalidFileType(false); // Reset the invalid file type warning when a file is removed
    return true;
  };
  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <ImgCrop rotationSlider>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        maxCount={1}
        customRequest={customRequest}
        accept=".jpg,.jpeg,.png" // Restrict file types to JPG and PNG
      >
        {fileList.length === 0 && (
          <div>
            +<br />
            Upload
          </div>
        )}
      </Upload>
    </ImgCrop>
  );
};

export default ImageUploader;