import React, {useContext, useEffect} from 'react';
import '../styles/scoring/judgingCriteria.scss';
import { mdiTrayArrowDown } from '@mdi/js';
import Exclamation from '../icons/AiFillExclamationCircle.svg';
import Icon from '@mdi/react';
import { Tooltip } from 'antd';
import { ScoringContext } from '../Provider/ScoringContext';
const JudgingCriteria = ({ title, criteria, handleNext, scored, handleEdit }) => {
  const { setScored } = useContext(ScoringContext);
  useEffect(() => {
    setScored(scored);
  }, [scored, setScored]);
  const formattedAverage = scored?.data?.average.toFixed(2) || null;
  const handlePrintClick = () => {
    window.print();
  };
  return (
    <>
      <div className="judging">
        <div className="judging-content">
          <div className="content-head no-print">
            <h1 className="H2">{title}</h1>
            <button onClick={handlePrintClick}>
              <Icon path={mdiTrayArrowDown} size={0.8} />
              <span className="Body">Download Criteria PDF</span>
            </button>
          </div>
          <div className="content-head2 no-print">
            <span className={scored?.status === 'scored' ? 'Body success-scored' : 'Body un-scored'}>{scored?.status === 'scored' ? 'Scored Successfuly' : 'In Progress'}</span>
            <div className="hint">
              <span className="Body">Hover for Scoring Value Per Attribute</span>
              <Tooltip placement="topRight" title={
                <div>
                  <span className="Body">Scoring Value per Attribute</span><br /><br />
                  <ul>
                    <li className="Body">1-2 = Made no attempt to the attributes</li>
                    <li className="Body">3-4 = Attempt to meet 25% of the attributes</li>
                    <li className="Body">5-6 = Achieved 50% of the attributes</li>
                    <li className="Body">7-8 = Achieved more than 75% of the attributes</li>
                    <li className="Body">9-10 = Exceeds all attributes</li>
                  </ul><br /><br />
                  <span className="Body">0 is not allowed to be used by the judges to score any attribute.</span>
                </div>
              }>
                <img src={Exclamation} width={16} height={16} alt="tooltips" />
              </Tooltip>
            </div>
          </div>
          {criteria && (
            <div className="content-table">
              <table>
                <thead>
                  <tr>
                    <th className="Subtitle">Judging Criteria</th>
                    <th className="Subtitle">Weighting</th>
                    <th className="Subtitle">Attributes</th>
                    <th className="Subtitle">Weighting</th>
                    <th className="Subtitle no-print">Score</th>
                  </tr>
                </thead>
                <tbody>
                  {criteria.criterias.map((criterion) => (
                    <React.Fragment key={criterion.id}>
                      {criterion.attributes.map((attribute, attrIndex) => {
                        let score = '-';
                        if (scored?.status === 'scored') {
                          // Find the score object for the current criterion
                          const matchingScore = scored.data.scores.find(scoreObj => scoreObj.id === criterion.id);
                          if (matchingScore) {
                            // Find the attribute score within the matching score object
                            const attributeScore = matchingScore.attributes.find(attr => attr.id === attribute.id);
                            if (attributeScore) {
                              score = attributeScore.score;
                            }
                          }
                        }
                        return (
                          <tr key={attribute.id}>
                            {attrIndex === 0 && (
                              <>
                                <td rowSpan={criterion.attributes.length}>{criterion.name}</td>
                                <td className="text-center" rowSpan={criterion.attributes.length}>{(criterion.weight * 100).toFixed(0)}%</td>
                              </>
                            )}
                            <td>{attribute.name}</td>
                            <td className="text-center">{(attribute.weight * 100).toFixed(0)}%</td>
                            <td className={scored?.status === 'scored' ? 'text-center text-scored Subtitle no-print' : 'text-center no-print'}>{score}</td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className={scored?.status === 'scored' ? 'content-scoring-scored no-print' : 'content-scoring no-print'}>
            {scored?.status !== 'scored' && <button className="Body btn-start" onClick={handleNext}>Start Scoring</button>}
            {scored?.status === 'scored' && (
              <>
                <h2 className="H2">Total Score: {formattedAverage} / 10</h2>
                <button className="Body btn-edit" onClick={handleEdit}>Edit Score
                </button>
              </>
            )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default JudgingCriteria;