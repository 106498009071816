import React , { useState } from 'react';
import '../styles/adminReport.scss'
import Logo from '../images/logo.png';
import { mdiTrayArrowDown } from '@mdi/js';
import Icon from '@mdi/react';
import LogoutModal from '../components/modal/logoutModal';
import axios from 'axios';
function CreateSuccess() {
    document.title = 'Admin report'
    const token = localStorage.getItem('token')
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const handleLogoutModalOpen = () => {
        setIsLogoutModalOpen(true);
      }
      const handleLogoutModalClose = () => {
        setIsLogoutModalOpen(false);
      }
      const handleLogoutConfirm = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = '/';
      }
      const downloadSubmissionReport = async () => {
        try {
            const response = await axios.get('https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/admin/export/submission', {
              responseType: 'blob',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition.split('filename=')[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } catch (error) {
            console.error('Error downloading submission report:', error);
          }
      }
      const downloadscoringReport = async () => {
        try {
            const response = await axios.get('https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/admin/export/scoring', {
              responseType: 'blob',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition.split('filename=')[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } catch (error) {
            console.error('Error downloading submission report:', error);
          }
      }
    return (
        <>
            <div className="report-page-container">
                <div className="report-page">
                    <div className="content">
                        <div className="section-top">
                            <img src={Logo} width={183} height={88} alt="Logo" />
                            <h1 className="H1">Admin Reports</h1>
                            <p className="Body">Monitor the progress of project submissions and <br /> judge scoring by downloading detailed reports.</p>
                        </div>
                        <div className="section-end">
                            <button onClick={downloadSubmissionReport} className="btn-download">
                                <Icon path={mdiTrayArrowDown} size={0.7}/>
                                Download Submission Report</button>
                            <button onClick={downloadscoringReport} className="btn-download">
                                <Icon path={mdiTrayArrowDown} size={0.7}/>
                                Download Scoring Report</button>
                            <button className="btn-logout" onClick={handleLogoutModalOpen}>Log out</button>
                        </div>
                    </div>
                </div>
            </div>
            <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm} admin={true}/>
        </>
    );
}

export default CreateSuccess;