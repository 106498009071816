import {React, useState} from 'react'
import { Button, Modal } from 'antd';
import '../../styles/loginButton.scss';
import Icon from '@mdi/react';
import { mdiLoading, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ForgetPassword from './forgetPassword';
function LoginButton() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();
  const showModal = () => {
    setOpen(true);
  };
  const emailInput = (e) => {
    setEmail(e.target.value);
    if (e.target.value) {
      setEmailError(false);
    }
  }
  const passwordInput = (e) => {
    setPassword(e.target.value);
    if(e.target.value) {
      setPasswordError(false);
    }
  }
  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword)
  }
  const validateLogin = (e) => {
    e.preventDefault();
    let hasError = false;
    if (!email) {
     setEmailError('Please enter your email.');
     hasError = true;
    } else if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      hasError = true;
    }
    else {
      setEmailError('');
    }
    if (!password) {
     setPasswordError('Please enter your password.');
     hasError = true;
    } else {
     setPasswordError('');
    }
    if (!hasError) {
      setLoading(true);
      login();
    }
  };
  const handleForgetPassword = (e) => {
    e.preventDefault()
    setForgetPasswordModal(true);
    setOpen(false)
  }

  const login = async () => {
    try {
      const response = await axios.post('https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/auth/login', {
        email,
        password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        const expiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
        localStorage.setItem('tokenExpiry', expiryTime);
        setTimeout(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiry');
          localStorage.removeItem('user');
        }, 24 * 60 * 60 * 1000);
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get('https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/auth/me', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          localStorage.setItem('user', JSON.stringify(response.data));
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
        setLoginFailed(false);
        setOpen(false);
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData.role === 'judge') { 
          navigate('/award-categories')
        } else if (userData.role === 'coordinator') {
          navigate('/overview');
        } else if (userData.role === 'admin') {
          navigate('/admin-report')
        }
        else {
          navigate('/')
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
      setPasswordError('Your email or password is incorrect.');
      setLoginFailed(true);
    } finally {
      setLoading(false);
    }
  }
  const handleCancel = () => {
    setOpen(false);
  };

    return (
    <>
      <Button type="primary" className="Body" onClick={showModal}>
        Log in to Your Account
      </Button>
      <Modal
        centered
        okText="Log In"
        open={open}
        onCancel={handleCancel}
        footer={null}
        className="loginModal"
      >
        <div className="login-modal">
        <h1 className="H1 login-title">Log in to Your Account</h1>
        <p className="Body login-desc">Welcome to Asean Digital Awards Judging Systems</p>
        <form className="login-form">
          <div className="login-email">
            <label className="Subtitle" htmlFor="email">Email</label>
            <input
            value={email}
            onChange={emailInput}
            className={loginFailed || emailError ? 'input-mail-error' : 'input-mail'}
            id="email" 
            type="text" />
            { emailError && <p className="Body mail-error">{emailError}</p> }
          </div>
          <div className="login-pwd">
            <label className="Subtitle" htmlFor="password">Password</label>
            <div className="password-box">
            <input
            value={password}
            onChange={passwordInput}
            className={loginFailed || passwordError ? 'input-pwd-error' : 'input-pwd'}
            id="password" 
            type={showPassword? "text" : "password"} />
            <button onClick={toggleShowPassword} className={loginFailed || passwordError ? 'show-password-icon-err' : 'show-password-icon'}>
              <Icon path={showPassword ? mdiEyeOutline : mdiEyeOffOutline} size={0.7} />
            </button>
            </div>
            { passwordError && <p className="Body pwd-error">{passwordError}</p> }
          </div>
            <button
            onClick={validateLogin}
            type="submit" 
            className="login-btn Body"> {loading? <Icon path={mdiLoading} className='rotate' size={1} /> : 'Log in' }
            </button>
            <button
            className="Subtitle forget-pwd"
            onClick={handleForgetPassword}
            >
              Forget password
            </button>
        </form>
        </div>
      </Modal>
      <ForgetPassword open={forgetPasswordModal} onClose={() => setForgetPasswordModal(false)} />
    </>
    );
}

export default LoginButton;