import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './app/landingPage';
import SubmissionOverview from './app/submissionOverview';
import SubmissionForm from './app/submissionForm';
import CreateSuccess from './app/createSucess';
import EditSubmissionForm from './app/editSubmissionForm';
import AwardCategories from './app/awardCategories';
import ProjectListByCategory from './app/projectListByCategory';
import ProjectInfo from './app/projectInfo';
import AdminReport from './app/adminReport';
import Summary from './app/summary';
import { ScoringProvider } from './Provider/ScoringContext';
function AppRouter() {
  return (
    <ScoringProvider>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/overview" element={<SubmissionOverview />} />
        <Route path="/overview/submission-form" element={<SubmissionForm />} />
        <Route path="/overview/edit-submission-form/:id" element={<EditSubmissionForm />} />
        <Route path="/create-success" element={<CreateSuccess />}/>
        <Route path="/award-categories" element={<AwardCategories />} />
        <Route path="/award-categories/:title" element={<ProjectListByCategory />} />
        <Route path="/award-categories/:title/summary" element={<Summary />}/>
        <Route path="/award-categories/:title/:projectId" element={<ProjectInfo />} />
        <Route path="/admin-report" element={<AdminReport />} />
      </Routes>
    </Router>
    </ScoringProvider>
  );
}

export default AppRouter;