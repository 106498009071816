import React from 'react';
import Icon from '@mdi/react';
import { mdiEmailOutline } from '@mdi/js';
import '../styles/footer.scss';
function Footer() {
  return (
    <>
    <div className="footer no-print">
        <div className="content">
            <div className="content-contact Body">
              <div className="left-box">
                <p className="Body">If you have any inquiries, please contact our email</p>
                  <div className="email-box">
                    <Icon path={mdiEmailOutline} className='text-white' width={32} height={32}/>
                    <span className="email Body">ADW2025@depa.or.th</span>
                  </div>
              </div>
                  <div className="right-box">
                    <span className="Body">Visit our website: &nbsp;
                      <a href="https://www.aseandigitalawards2025.com/" target="_blank" rel="noreferrer" className="Body">The ASEAN Digital Awards 2025</a>  
                    </span>
              </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default Footer;