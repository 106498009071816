import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Footer from '../components/footer';
import LogoutModal from '../components/modal/logoutModal';
import logo from '../images/logo.png';
import DigitalContent from '../images/categories/digital-content.svg'
import DigitalInclusivity from '../images/categories/digital-inclusivity.svg'
import DigitalInnovation from '../images/categories/digital-innovation.svg'
import DigitalStartup from '../images/categories/digital-startup.svg'
import PrivateSector from '../images/categories/private-sector.svg'
import PublicSector from '../images/categories/public-sector.svg'
import { Link, useNavigate } from 'react-router-dom';
import '../styles/awardCategories.scss';
const categoryDetails = {
  1: { title: 'Public Sector', icon: PublicSector, description: 'This consists of digital products, services or programs which are public-sector related, such as e-government.' },
  2: { title: 'Private Sector', icon: PrivateSector, description: 'This consists of digital products, services or programs which are private-sector related, such as Industrial application, e-logistics and supply chain management, finance industry application, communication, e-health, tourism, and hospitality, etc.' },
  3: { title: 'Digital Inclusivity', icon: DigitalInclusivity, description: 'Digital products, services or programs which empower citizens/consumers to participate in the digital economy and society, through equipping them with the skills, motivation, and opportunities to harness the power of digital technology and to go online safely.' },
  4: { title: 'Digital Content', icon: DigitalContent, description: 'This consists of digital products and/or services which are related to the process of combining text, sound, pictures, and videos to create a diversion that holds the attention (i.e. entertainment) in the form of multimedia, infotainment, immersion, and interaction.' },
  5: { title: 'Digital Start-up', icon: DigitalStartup, description: 'Digital start-ups at an early stage of inception (i.e., with commercialized product(s) or with a minimum viable product (MVP)), which embrace digitalization and technological advancement to improve productivity, revamp business models and raise overall living standards amongst citizens/consumers to deliver social and economic impact.' },
  6: { title: 'Digital Innovation', icon: DigitalInnovation, description: 'Digital products, services or programs arising from tertiary and research institutions, which promote innovation as a key competitive advantage to create outstanding impact on the way businesses operate or greatly enhance the quality of lives for end users.' },
};
function AwardCategories() {
  document.title = 'Select the Award Category | Judging.AseanDigitalAwards2025';
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const categoryDetailsRef = useRef(categoryDetails);
  const [categoryCards, setCategoryCards] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/scores/category', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        const categoryList = response.data.map(category => ({
          ...category,
          ...categoryDetailsRef.current[category.category]
        }));
        setCategoryCards(categoryList);
      } catch (err) {
        console.log(err)
      }
    }
    fetchCategories();
  }, [token])
  const handleLogoutModalOpen = () => {
    setIsLogoutModalOpen(true);
  }
  const handleLogoutModalClose = () => {
    setIsLogoutModalOpen(false);
  }
  const handleLogoutConfirm = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.href = '/';
  }
  const formatTitleForURL = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  }
  const selectCategory = (index) => {
    const category = categoryCards[index]
    const formattedTitle = formatTitleForURL(category.title)
    navigate(`/award-categories/${formattedTitle}`, { state: { categoryId: index + 1 } })
  }
  return (
    <>
      <div className="award-categories">
        <div className="content">
          <section className="top">
            <div className="top-logo">
              <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo" />
            </div>
            <div className="top-breadcrumb">
              <span onClick={handleLogoutModalOpen} className="bread-home">Home</span>
              <span> / </span>
              <Link to="/award-categories" className="bread-award-categories">Award Categories</Link>
            </div>
            <div className="top-title">
              <span className="H1">Select the Award Category</span>
            </div>
            <div className="top-desc">
              <p className="Body">
                Choose a category below to review and score the submitted projects.
              </p>
            </div>
          </section>
          <section className="main">
            {categoryCards.map((category, index) => (
              <div key={index}
                onClick={() => {
                  // selectCategory(index)
                  if (category.allow_scoring) {
                    selectCategory(index)
                  }
                }} 
                className="category-card">
                <div className={category.allow_scoring ? "cards" : "cards not-allow-card"}>
                  <div className="card-top">
                    <img src={category.icon} width={40} height={40} alt={`Icon for category ${category.category}`} />
                    <h1 className="Title-1">{category.title}</h1>
                    {category.project_count === category.scored_count && category.project_count !== '00' && (
                      <span className="Body status">DONE</span>
                    )}
                  </div>
                  <div className="card-main">
                    <p className="Body">{category.description}</p>
                  </div>
                  <div className="card-bottom">
                    <p className="Subtitle">Scored Project: {category.scored_count}/{category.project_count}</p>
                  </div>
                </div>
              </div>
            ))}
          </section>
        </div>
      </div>
      <Footer />
      <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm} />
    </>
  )
}

export default AwardCategories;