import React from 'react';
import successIcon from '../icons/success.svg';
import { useLocation, Link } from 'react-router-dom';
import Footer from '../components/footer';
import { useNavigate } from 'react-router-dom';
import '../styles/createSuccess.scss'

function CreateSuccess() {
    const navigate = useNavigate();
    const backToOverview = () => {
        navigate('/overview')
    }
    document.title = "Submission Complete!"
    const location = useLocation();
    const { team_id } = location.state || {};
    return (
        <>
            <div className="success-page-container">
                <div className="success-page">
                    <div className="content">
                        <div className="section-top">
                            <img src={successIcon} alt="success" />
                            <h1 className="H1">Submitted Successfully</h1>
                            <p className="Title-1">Your Team ID is <span>{team_id}</span></p>
                        </div>
                        <div className="section-middle">
                            <p>Thank you for submitting your project for the ASEAN Digital Awards 2025.
                                Your project has been successfully uploaded and is now under review.
                                You will receive a confirmation email shortly with the details of your submission.
                                You can track your project’s status and stay updated on importance announcements by <br />
                                visiting <a href="https://www.aseandigitalawards2025.com/" rel="noreferrer" target='_blank'> our official website</a>
                            </p>
                        </div>
                        <div className="section-end">
                            <button onClick={backToOverview}>
                                <Link to="/overview">
                                    Back to Submission Overview
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default CreateSuccess;