// src/pages/ProjectListByCategory.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../components/footer';
import LogoutModal from '../components/modal/logoutModal';
import '../styles/projectListByCategory.scss';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import successIcon from '../icons/success.svg';
import thumbnailLogo from '../images/logo-whitebg.png';
import BN from '../images/flags/bn.svg';
import ID from '../images/flags/id.svg';
import KH from '../images/flags/kh.svg';
import LA from '../images/flags/la.svg';
import MM from '../images/flags/mm.svg';
import MY from '../images/flags/my.svg';
import PH from '../images/flags/ph.svg';
import SG from '../images/flags/sg.svg';
import TH from '../images/flags/th.svg';
import VN from '../images/flags/vn.svg';
import { Pagination, Modal } from 'antd';
const ProjectListByCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const { categoryId, successStatus, isEdit } = location.state || {};
  const [modalOpen, setModalOpen] = useState(successStatus);
  const [categoryData, setCategoryData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const token = localStorage.getItem('token');
  const { title } = useParams();
  const countryFlags = {
    BN: BN,
    ID: ID,
    KH: KH,
    LA: LA,
    MM: MM,
    MY: MY,
    PH: PH,
    SG: SG,
    TH: TH,
    VN: VN
  }
  useEffect(() => {
    const fetchCategoryData = async (page = 1) => {
      try {
        const response = await axios.get(`https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/scores/category/${categoryId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            page: page
          }
        });
        setCategoryData(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCategoryData(currentPage);
  }, [categoryId, token, currentPage]);
  const allScored = categoryData?.items?.length > 0 && categoryData?.items?.every(item => item.status === 'scored');
  useEffect(() => {
    if (successStatus) {
      setModalOpen(true);
    }
  }, [successStatus]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  if (!categoryData) {
    return <div>Loading...</div>;
  }
  const handleLogoutModalOpen = () => {
    setIsLogoutModalOpen(true);
  }
  const handleLogoutModalClose = () => {
    setIsLogoutModalOpen(false);
  }
  const handleLogoutConfirm = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.href = '/';
  }
  const formatTitle = (title) => {
    if (title === 'digital-start-up') {
      return 'Digital Start-up';
    } else {
      return title
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  };
  const selectProject = (itemId) => {
    navigate(`/award-categories/${title}/${itemId}`)
  }

  const handleModalClose = () => {
    setModalOpen(false)
    navigate(location.pathname, { state: { ...location.state, successStatus: false, isEdit: false } });
  }
  const navigateToSummary = () => {
    navigate(`/award-categories/${title}/summary`, { state: { categoryId } });
}
  document.title = `${formatTitle(title)} | Judging.AseanDigitalAwards2025`
  return (
    <>
      <div className="container-project-list-categories">
        <div className="project-list-categories">
          <div className="content">
            <section className="top">
              <div className="top-logo">
                <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo" />
              </div>
              <div className="top-breadcrumb">
                <span onClick={handleLogoutModalOpen} className="bread-home">Home</span>
                <span> / </span>
                <Link to="/award-categories" className="bread-award-categories">Award Categories</Link>
                <span> / </span>
                <Link to={`/award-categories/${title}`} className="bread-each-categories">{formatTitle(title)}</Link>
              </div>
              <div className="top-title">
                <span className="H1">{formatTitle(title)}</span>
                {allScored && <span className="Body status">Done</span>}
              </div>
              <div className="top-desc">
                <p className="Body">
                  Review the submitted projects for evaluation. Click on each project to view details and submit your scores based on the judging criteria.
                </p>
              </div>
              <div onClick={navigateToSummary} className="top-btn">
                <button className="Body">Summary</button>
          </div>
        </section>
        <section className="main">
          {categoryData.items.map((item) => (
            <div key={item.id} onClick={() => selectProject(item.id)} className="project-card">
              <div className="project-thumbnail">
                <img src={item.thumbnail?.url || thumbnailLogo} alt={item.title} width={184} height={152} />
                <img className="country-thumbnail" src={countryFlags[item.country]} width={30} height={24} alt={`${item.country} flag`} />
              </div>
              <div className="project-details">
                <div className="detail-top">
                  <h1 className="Title-1">{item.title}</h1>
                  <div className="total-score">
                    <span className="Subtitle score-title">Total Score:</span>
                    <span className="Body score-point">{item.score.toFixed(2)} / 10</span>
                  </div>
                </div>
                <p className="Body description">{item.description}</p>
                <div className="detail-bottom">
                  <div className="team-name">
                    <span className="Subtitle">Team name: </span>
                    <span className="Body">{item.team_name}</span>
                  </div>
                  <div className="team-id">
                    <span className="Subtitle">Team ID: </span>
                    <span className="Body">[{item.team_id}]</span>
                  </div>
                  <div className="state">
                    <span className="Subtitle">State: </span>
                    <span className={`Body ${item.status === 'unscored' ? 'state-status-not-done' : 'state-status-done'}`}>
                      {item.status === 'unscored' ? 'In Progress' : 'Done'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
        {categoryData.count !== 0 && <Pagination onChange={handlePageChange} current={currentPage} total={categoryData.count} pageSize={categoryData.limit} />}
      </div>
    </div >
      <Footer />
      </div >
      <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm} />
      <Modal className="success-modal" closeIcon={false} footer={null} open={modalOpen}>
        <div className="success-modal-content">
          <img src={successIcon} alt="success" />
          <h1 className="H1">{isEdit ? <>Changes Saved <br /> Successfully</> : 'Scored Successfully'}</h1>
          <p className="Body">{isEdit ? <>The project Score has been updated successfully. All <br />changes have been saved. </> : 'The project score has been successfully submitted.'}</p>
          <div className="button-section">
            <button className="btn-close" onClick={handleModalClose}>Close</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProjectListByCategory;