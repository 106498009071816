import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/footer';
import LogoutModal from '../components/modal/logoutModal';
import logo from '../images/logo.png';
import Exclamation from '../icons/AiFillExclamationCircle.svg';
import '../styles/projectInfo.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BN from '../images/flags/bn.svg';
import ID from '../images/flags/id.svg';
import KH from '../images/flags/kh.svg';
import LA from '../images/flags/la.svg';
import MM from '../images/flags/mm.svg';
import MY from '../images/flags/my.svg';
import PH from '../images/flags/ph.svg';
import SG from '../images/flags/sg.svg';
import TH from '../images/flags/th.svg';
import VN from '../images/flags/vn.svg';
import AdditionalFiles from '../components/showAdditionalFile';
import TabScoring from '../components/tabScoring';
import Icon from '@mdi/react';
import { mdiArrowUp } from '@mdi/js';
import { ScoringContext } from '../Provider/ScoringContext';
// import { Document, Page, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const ProjectInfomation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { summaryStatus } = location.state || {};
    // console.log(summaryStatus);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const { projectId, title } = useParams();
    const token = localStorage.getItem('token');
    const [projectDetails, setProjectDetails] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const { scored } = useContext(ScoringContext);
    const countryFlags = {
        BN: BN,
        ID: ID,
        KH: KH,
        LA: LA,
        MM: MM,
        MY: MY,
        PH: PH,
        SG: SG,
        TH: TH,
        VN: VN
    }
    const countryMapping = {
        BN: 'Brunei',
        ID: 'Indonesia',
        KH: 'Cambodia',
        LA: 'Laos',
        MM: 'Myanmar',
        MY: 'Malaysia',
        PH: 'Philippines',
        SG: 'Singapore',
        TH: 'Thailand',
        VN: 'Vietnam'
    };
    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                const response = await axios.get(`https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/${projectId}/detail`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setProjectDetails(response.data);
            } catch (error) {
                console.error('Error fetching project details:', error);
            }
        };
        if (projectId && token) {
            fetchProjectDetails();
        }
    }, [projectId, token]);

    if (!projectDetails) {
        return <div>Loading...</div>;
    }
    const handleLogoutModalOpen = () => {
        setIsLogoutModalOpen(true);
    }
    const handleLogoutModalClose = () => {
        setIsLogoutModalOpen(false);
    }
    const handleLogoutConfirm = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    const formatCategory = (category) => {
        if (!category) return '';
        return category
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const formatTitleForURL = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    }
    const handleBackCategory = () => {
        navigate(`/award-categories/${formatTitleForURL(title)}`, { state: { categoryId: projectDetails.category } })
    }
    const handleBackSummary = () => {
        navigate(`/award-categories/${formatTitleForURL(title)}/summary`, { state: { categoryId: projectDetails.category } })
    }
    const handleImageClick = () => {
        setSelectedImage(projectDetails.infographic.url);
    }
    const handleScrollToScoring = () => {
        const scoringSection = document.getElementById('scoring-section');
        if (scoringSection) {
            scoringSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const formatText = (text) => {
        if (!text) return '-';
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };
    // console.log(projectDetails.propose_and_goals);
    // console.log(projectDetails.problem_and_solution)
    // console.log(projectDetails.key_features)
    // console.log(projectDetails.innovation)
    document.title = `${projectDetails.title} - ${formatCategory(title)} | Judging.AseanDigitalAwards2025`
    return (
        <>
            <div className="project-info">
                <div className="content">
                    <section className="top no-print">
                        <div className="top-logo">
                            <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo" />
                        </div>
                        <div className="top-breadcrumb">
                            <span onClick={handleLogoutModalOpen} className="bread-home">Home</span>
                            <span> / </span>
                            <Link to="/award-categories" className="bread-award-categories">Award Categories</Link>
                            <span> / </span>
                            <span onClick={handleBackCategory} className="bread-category">{formatCategory(title)}</span>
                            {summaryStatus && <>
                                <span> / </span>
                                <span onClick={handleBackSummary} className="bread-category">Summary</span>
                            </>}
                            <span> / </span>
                            <span className="bread-project-info">Team Information</span>
                        </div>
                        <div className="top-title">
                            <span className="H1">Team Information</span>
                        </div>
                        <div className="top-desc">
                            <p className="Body">
                                To scoring the project. please follow instructions below :
                            </p>
                        </div>
                        <div className="top-instruction">
                            <p className="Subtitle">Instruction:</p>
                            <ul className="Body">
                                <li>1) Review the project details on this page.</li>
                                <li>2) Click "Scoring" at the top right.</li>
                                <li>3) In the scoring modal, view the judging criteria table.</li>
                                <li>4) Click "Start Scoring" to begin.</li>
                                <li>5) Select scores from 1-10 using the dropdown for each criterion.</li>
                                <li>6) The system will generate your total score in real-time (Max score: 10).</li>
                                <li>7) Continue scoring through each section by clicking "Next."</li>
                                <li>8) Once complete, click "Submit" to confirm and save your scores.</li>
                                <li>9) You can edit your scores anytime before the event's closing date.</li>
                            </ul>
                        </div>
                    </section>
                    <section className="main no-print">
                        <div className="main-btn">
                            <button className="Body btn-scoring" onClick={handleScrollToScoring}>{scored?.status === 'scored' ? 'Edit Score' : 'Scoring'}</button>
                        </div>
                        <div className="main-project-detail">
                            <div className="project-title">
                                <h1 className="Title-1">{projectDetails.title}</h1>
                                <p className="Body">{projectDetails.description}</p>
                            </div>
                            <div className="project-information">
                                <div className="information-left">
                                    <h1 className="Title-1">Team Information:</h1>
                                    <div className="topic country">
                                        <h3 className="Subtitle">Country:</h3>
                                        <div className="country-detail">
                                            <p className="Body">{countryMapping[projectDetails.country]}</p>
                                            <img src={countryFlags[projectDetails.country]} width={32} height={32} alt={`${projectDetails.country} flag`} />
                                        </div>
                                    </div>
                                    <div className="topic category">
                                        <h3 className="Subtitle">Select Category:</h3>
                                        <p className="Body">{formatCategory(title)}</p>
                                    </div>
                                    <div className="topic email">
                                        <h3 className="Subtitle">Contact Email:</h3>
                                        <p className="Body">{projectDetails.contact_email}</p>
                                    </div>
                                    <div className="topic team-id">
                                        <h3 className="Subtitle">Team ID:</h3>
                                        <p className="Body">{projectDetails.team_id}</p>
                                    </div>
                                    <div className="topic team-name">
                                        <h3 className="Subtitle">Team Name:</h3>
                                        <p className="Body">{projectDetails.team_name}</p>
                                    </div>
                                    <div className="topic team-members">
                                        <h3 className="Subtitle">Team Members:</h3>
                                        <ul>
                                            {projectDetails.team_members.map((member, index) => (
                                                <li key={index} className="Body">{member.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="additional-file">
                                        <h1 className="Title-1">Additional Files:</h1>
                                        <AdditionalFiles files={projectDetails.additional_files} />
                                    </div>
                                </div>
                                <div className="information-right">
                                    <div className="video">
                                        <h1 className="Title-1">Video URL</h1>
                                        {(projectDetails.youtube_id || projectDetails.google_drive_id) && <iframe
                                            width="490"
                                            height="246"
                                            src={projectDetails.youtube_id ? `https://www.youtube.com/embed/${projectDetails.youtube_id}` : `https://drive.google.com/file/d/${projectDetails.google_drive_id}/preview`}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>}
                                        {(!projectDetails.youtube_id && !projectDetails.google_drive_id) && <a className="video-href" target="_blank" rel="noreferrer" href={projectDetails.video_url}>{projectDetails.video_url}</a>}
                                        <p className="video-caution">
                                            <img src={Exclamation} alt="Caution" />
                                            <span className="Body">Internal use only</span>
                                        </p>
                                    </div>
                                    <div className="pitch-deck">
                                        <h1 className="Title-1">Pitch Deck:</h1>
                                        {projectDetails.pitch_deck && projectDetails.pitch_deck.url ? (
                                            <div className="pdf-preview">
                                                <iframe
                                                    src={projectDetails.pitch_deck.url}
                                                    title="PDF file"
                                                    width="494"
                                                    height="277px"
                                                />
                                                <a
                                                    href={projectDetails.pitch_deck.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="view-fullsize"
                                                >
                                                    Click to view Pitch Deck in new tab
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="Body no-file">No file</div>
                                        )}
                                    </div>
                                    <div className="infographic">
                                        <h1 className="Title-1">Infographic:</h1>
                                        {projectDetails.infographic && projectDetails.infographic.url ? (
                                            <>
                                                <div className="cover-img">
                                                    <img
                                                        className="info-img"
                                                        onClick={handleImageClick}
                                                        src={projectDetails.infographic.url}
                                                        alt="infographic-picture"
                                                        width={500}
                                                        height={280}
                                                    />
                                                    <div className="view-text" onClick={handleImageClick}>View</div>
                                                </div>
                                                {selectedImage && (
                                                    <div className="image-preview-overlay" onClick={() => setSelectedImage(null)}>
                                                        <div className="image-preview-container">
                                                            <img src={selectedImage} alt="Preview" className="image-preview" />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="no-file Body">No file</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="main-project-detail2">
                                <div className="box goal">
                                    <h1 className="Title-1">Purpose and Goal :</h1>
                                    <p className="Body">{formatText(projectDetails.propose_and_goals)}</p>
                                </div>
                                <div className="box impact">
                                    <h1 className="Title-1">Problem and Solution :</h1>
                                    <p className="Body">{formatText(projectDetails.problem_and_solution)}</p>
                                </div>
                                <div className="box key">
                                    <h1 className="Title-1">Key Feature and Impact :</h1>
                                    <p className="Body">{formatText(projectDetails.key_features)}</p>
                                </div>
                                <div className="box innovation">
                                    <h1 className="Title-1">Innovation and methodology :</h1>
                                    <p className="Body">{formatText(projectDetails.innovation)}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="scoring-section" className="final">
                        <div className="scoring">
                            <div className="scoring-top print">
                                <h1 className="H1">Scoring Section</h1>
                                <p className="Body">Please enter your scores in each category based on the attributes provided.
                                    Ensure your assessments reflect the weighting of each criterion and attribute.
                                    Once complete, submit your scores for this project.
                                    You may revise your scores until the final submission deadline.
                                </p>
                            </div>
                            <div className="scoring-main">
                                <TabScoring />
                            </div>
                        </div>
                    </section>
                </div>
                <div className="floating-arrow no-print">
                    <div onClick={scrollToTop} className="arrow">
                        <Icon path={mdiArrowUp} size={1.3} />
                    </div>
                </div>
            </div>
            <Footer />
            <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm} />
        </>
    );
};

export default ProjectInfomation