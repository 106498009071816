import React, { useState } from 'react';
import { Modal } from 'antd';
import '../../styles/forgetPassword.scss';
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';
import { isValidEmail } from '../../utils/validation';
import axios from 'axios';
const ForgetPassword = ({ open, onClose }) => {
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const handleEmailInput = (e) => {
    setEmailInput(e.target.value)
    if (!e.target.value|| !isValidEmail(e.target.value)) {
        setDisabledButton(true)
    } else {
        setDisabledButton(false)
    }
    if (!e.target.value) {
        setEmailError('Please enter your email.');
      } else if (!isValidEmail(e.target.value)) {
        setEmailError('Please enter a valid email address.');
      } else {
        setEmailError('');
      }
  }
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!emailError && emailInput) {
        setLoading(true)
        setDisabledButton(false)
        try {
            const response = await axios.post('https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/auth/reset-password', {
                email: emailInput,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            if (response.status === 200) {
              setSuccessModal(true)
            }
        } catch(err) {
            console.error(err);
        } finally {
            setLoading(false)
            onClose()
        }
    }
  }

  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    onClose();
  };
  return (
    <>
    <Modal className="forget-pwd-modal" footer={null} open={open} onCancel={onClose}>
      <div className="forget-pwd-modal-content">
        <h1 className="H1">Reset Your Password</h1>
        <p className="Body">Enter your registered email address, and we'll send you instructions to reset your password.</p>
        <div className={"reset-pwd-section"}>
            <label htmlFor='email' className="Subtitle">Email</label>
            <input onChange={handleEmailInput} className={emailError ? "Body email-input-error" : "Body email-input"} id="email" type="text" value={emailInput} />
            { emailError && <span className="Body error-input">{emailError}</span> }
        <div className="button-section">
          <button onClick={handleResetPassword} className={disabledButton ? "Body btn-submit-disabled" : "Body btn-submit"}>
            {loading? <Icon path={mdiLoading} className='rotate' size={1} /> : 'Send Reset Link' }
          </button>
        </div>
        </div>
      </div>
    </Modal>

    <Modal className="success-modal" footer={null} open={successModal} onCancel={handleSuccessModalClose}>
        <div className="success-modal-content">
          <h1 className="H1">Reset Link Already Sent</h1>
          <p className="Body">Reset link has been sent to your email address. Follow the instructions to reset your password.</p>
        </div>
      </Modal>
    </>
    
  );
};

export default ForgetPassword;